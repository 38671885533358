import { Link } from "react-router-dom";

/**
 * Function used to render an accessible and clickable app store button link.
 *
 * @param {object} props
 * @param {string} props.className Optional classname passed from parent.
 * @returns {Element} Returns the app store badge as an anchor.
 */
export function AppStore({ className }) {
  return (
    <a
      href="https://apps.apple.com/us/app/fenix-xl/id1539718672"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <svg
        id="livetype"
        xmlns="http://www.w3.org/2000/svg"
        width="119.66407"
        height="40"
        viewBox="0 0 119.66407 40"
      >
        <title>Download_on_the_App_Store_Badge_US-UK_RGB_blk_4SVG_092917</title>
        <g>
          <g>
            <g>
              <path
                d="M110.13477,0H9.53468c-.3667,0-.729,0-1.09473.002-.30615.002-.60986.00781-.91895.0127A13.21476,13.21476,0,0,0,5.5171.19141a6.66509,6.66509,0,0,0-1.90088.627A6.43779,6.43779,0,0,0,1.99757,1.99707,6.25844,6.25844,0,0,0,.81935,3.61816a6.60119,6.60119,0,0,0-.625,1.90332,12.993,12.993,0,0,0-.1792,2.002C.00587,7.83008.00489,8.1377,0,8.44434V31.5586c.00489.3105.00587.6113.01515.9219a12.99232,12.99232,0,0,0,.1792,2.0019,6.58756,6.58756,0,0,0,.625,1.9043A6.20778,6.20778,0,0,0,1.99757,38.001a6.27445,6.27445,0,0,0,1.61865,1.1787,6.70082,6.70082,0,0,0,1.90088.6308,13.45514,13.45514,0,0,0,2.0039.1768c.30909.0068.6128.0107.91895.0107C8.80567,40,9.168,40,9.53468,40H110.13477c.3594,0,.7246,0,1.084-.002.3047,0,.6172-.0039.9219-.0107a13.279,13.279,0,0,0,2-.1768,6.80432,6.80432,0,0,0,1.9082-.6308,6.27742,6.27742,0,0,0,1.6172-1.1787,6.39482,6.39482,0,0,0,1.1816-1.6143,6.60413,6.60413,0,0,0,.6191-1.9043,13.50643,13.50643,0,0,0,.1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938V9.53613c0-.36621,0-.72949-.0078-1.09179,0-.30664,0-.61426-.0039-.9209a13.5071,13.5071,0,0,0-.1856-2.002,6.6177,6.6177,0,0,0-.6191-1.90332,6.46619,6.46619,0,0,0-2.7988-2.7998,6.76754,6.76754,0,0,0-1.9082-.627,13.04394,13.04394,0,0,0-2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002Z"
                // style="fill: #a6a6a6"
              />
              <path d="M8.44483,39.125c-.30468,0-.602-.0039-.90429-.0107a12.68714,12.68714,0,0,1-1.86914-.1631,5.88381,5.88381,0,0,1-1.65674-.5479,5.40573,5.40573,0,0,1-1.397-1.0166,5.32082,5.32082,0,0,1-1.02051-1.3965,5.72186,5.72186,0,0,1-.543-1.6572,12.41351,12.41351,0,0,1-.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131V8.44434S.88185,7.75293.8877,7.5498a12.37039,12.37039,0,0,1,.16553-1.87207,5.7555,5.7555,0,0,1,.54346-1.6621A5.37349,5.37349,0,0,1,2.61183,2.61768,5.56543,5.56543,0,0,1,4.01417,1.59521a5.82309,5.82309,0,0,1,1.65332-.54394A12.58589,12.58589,0,0,1,7.543.88721L8.44532.875H111.21387l.9131.0127a12.38493,12.38493,0,0,1,1.8584.16259,5.93833,5.93833,0,0,1,1.6709.54785,5.59374,5.59374,0,0,1,2.415,2.41993,5.76267,5.76267,0,0,1,.5352,1.64892,12.995,12.995,0,0,1,.1738,1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079,1.09179V30.4648c0,.3633,0,.7178-.0079,1.0752,0,.3252,0,.6231-.0039.9297a12.73126,12.73126,0,0,1-.1709,1.8535,5.739,5.739,0,0,1-.54,1.67,5.48029,5.48029,0,0,1-1.0156,1.3857,5.4129,5.4129,0,0,1-1.3994,1.0225,5.86168,5.86168,0,0,1-1.668.5498,12.54218,12.54218,0,0,1-1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002Z" />
            </g>
            <g id="_Group_" data-name="&lt;Group&gt;">
              <g id="_Group_2" data-name="&lt;Group&gt;">
                <g id="_Group_3" data-name="&lt;Group&gt;">
                  <path
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    d="M24.76888,20.30068a4.94881,4.94881,0,0,1,2.35656-4.15206,5.06566,5.06566,0,0,0-3.99116-2.15768c-1.67924-.17626-3.30719,1.00483-4.1629,1.00483-.87227,0-2.18977-.98733-3.6085-.95814a5.31529,5.31529,0,0,0-4.47292,2.72787c-1.934,3.34842-.49141,8.26947,1.3612,10.97608.9269,1.32535,2.01018,2.8058,3.42763,2.7533,1.38706-.05753,1.9051-.88448,3.5794-.88448,1.65876,0,2.14479.88448,3.591.8511,1.48838-.02416,2.42613-1.33124,3.32051-2.66914a10.962,10.962,0,0,0,1.51842-3.09251A4.78205,4.78205,0,0,1,24.76888,20.30068Z"
                    style={{ fill: "#fff" }}
                  />
                  <path
                    id="_Path_2"
                    data-name="&lt;Path&gt;"
                    d="M22.03725,12.21089a4.87248,4.87248,0,0,0,1.11452-3.49062,4.95746,4.95746,0,0,0-3.20758,1.65961,4.63634,4.63634,0,0,0-1.14371,3.36139A4.09905,4.09905,0,0,0,22.03725,12.21089Z"
                    style={{ fill: "#fff" }}
                  />
                </g>
              </g>
              <g>
                <path
                  d="M42.30227,27.13965h-4.7334l-1.13672,3.35645H34.42727l4.4834-12.418h2.083l4.4834,12.418H43.438ZM38.0591,25.59082h3.752l-1.84961-5.44727h-.05176Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M55.15969,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H48.4302v1.50586h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C53.645,21.34766,55.15969,23.16406,55.15969,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C52.30227,29.01563,53.24953,27.81934,53.24953,25.96973Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M65.12453,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H58.395v1.50586h.03418A3.21162,3.21162,0,0,1,61.312,21.34766C63.60988,21.34766,65.12453,23.16406,65.12453,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C62.26711,29.01563,63.21438,27.81934,63.21438,25.96973Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M71.71047,27.03613c.1377,1.23145,1.334,2.04,2.96875,2.04,1.56641,0,2.69336-.80859,2.69336-1.91895,0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.3877c-2.28027-.55078-3.33887-1.61719-3.33887-3.34766,0-2.14258,1.86719-3.61426,4.51855-3.61426,2.624,0,4.42285,1.47168,4.4834,3.61426h-1.876c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148,1.8584c0,.87793.6543,1.39453,2.25488,1.79l1.36816.33594c2.54785.60254,3.60645,1.626,3.60645,3.44238,0,2.32324-1.85059,3.77832-4.79395,3.77832-2.75391,0-4.61328-1.4209-4.7334-3.667Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M83.34621,19.2998v2.14258h1.72168v1.47168H83.34621v4.99121c0,.77539.34473,1.13672,1.10156,1.13672a5.80752,5.80752,0,0,0,.61133-.043v1.46289a5.10351,5.10351,0,0,1-1.03223.08594c-1.833,0-2.54785-.68848-2.54785-2.44434V22.91406H80.16262V21.44238H81.479V19.2998Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M86.065,25.96973c0-2.84863,1.67773-4.63867,4.29395-4.63867,2.625,0,4.29492,1.79,4.29492,4.63867,0,2.85645-1.66113,4.63867-4.29492,4.63867C87.72609,30.6084,86.065,28.82617,86.065,25.96973Zm6.69531,0c0-1.9541-.89551-3.10742-2.40137-3.10742s-2.40039,1.16211-2.40039,3.10742c0,1.96191.89453,3.10645,2.40039,3.10645S92.76027,27.93164,92.76027,25.96973Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M96.18606,21.44238h1.77246v1.541h.043a2.1594,2.1594,0,0,1,2.17773-1.63574,2.86616,2.86616,0,0,1,.63672.06934v1.73828a2.59794,2.59794,0,0,0-.835-.1123,1.87264,1.87264,0,0,0-1.93652,2.083v5.37012h-1.8584Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="M109.3843,27.83691c-.25,1.64355-1.85059,2.77148-3.89844,2.77148-2.63379,0-4.26855-1.76465-4.26855-4.5957,0-2.83984,1.64355-4.68164,4.19043-4.68164,2.50488,0,4.08008,1.7207,4.08008,4.46582v.63672h-6.39453v.1123a2.358,2.358,0,0,0,2.43555,2.56445,2.04834,2.04834,0,0,0,2.09082-1.27344Zm-6.28223-2.70215h4.52637a2.1773,2.1773,0,0,0-2.2207-2.29785A2.292,2.292,0,0,0,103.10207,25.13477Z"
                  style={{ fill: "#fff" }}
                />
              </g>
            </g>
          </g>
          <g id="_Group_4" data-name="&lt;Group&gt;">
            <g>
              <path
                d="M37.82619,8.731a2.63964,2.63964,0,0,1,2.80762,2.96484c0,1.90625-1.03027,3.002-2.80762,3.002H35.67092V8.731Zm-1.22852,5.123h1.125a1.87588,1.87588,0,0,0,1.96777-2.146,1.881,1.881,0,0,0-1.96777-2.13379h-1.125Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M41.68068,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C44.57522,13.99463,45.01369,13.42432,45.01369,12.44434Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M51.57326,14.69775h-.92187l-.93066-3.31641h-.07031l-.92676,3.31641h-.91309l-1.24121-4.50293h.90137l.80664,3.436h.06641l.92578-3.436h.85254l.92578,3.436h.07031l.80273-3.436h.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M53.85354,10.19482H54.709v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915h-.88867V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M59.09377,8.437h.88867v6.26074h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M61.21779,12.44434a2.13346,2.13346,0,1,1,4.24756,0,2.1338,2.1338,0,1,1-4.24756,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C64.11232,13.99463,64.5508,13.42432,64.5508,12.44434Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M66.4009,13.42432c0-.81055.60352-1.27783,1.6748-1.34424l1.21973-.07031v-.38867c0-.47559-.31445-.74414-.92187-.74414-.49609,0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953,1.83984-1.26953,1.12891,0,1.76563.562,1.76563,1.51318v3.07666h-.85547v-.63281h-.07031a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,66.4009,13.42432Zm2.89453-.38477v-.37646l-1.09961.07031c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,69.29543,13.03955Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M71.34816,12.44434c0-1.42285.73145-2.32422,1.86914-2.32422a1.484,1.484,0,0,1,1.38086.79h.06641V8.437h.88867v6.26074h-.85156v-.71143h-.07031a1.56284,1.56284,0,0,1-1.41406.78564C72.0718,14.772,71.34816,13.87061,71.34816,12.44434Zm.918,0c0,.95508.4502,1.52979,1.20313,1.52979.749,0,1.21191-.583,1.21191-1.52588,0-.93848-.46777-1.52979-1.21191-1.52979C72.72121,10.91846,72.26613,11.49707,72.26613,12.44434Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M79.23,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C82.12453,13.99463,82.563,13.42432,82.563,12.44434Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M84.66945,10.19482h.85547v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915H87.605V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M93.51516,9.07373v1.1416h.97559v.74854h-.97559V13.2793c0,.47168.19434.67822.63672.67822a2.96657,2.96657,0,0,0,.33887-.02051v.74023a2.9155,2.9155,0,0,1-.4834.04541c-.98828,0-1.38184-.34766-1.38184-1.21582v-2.543h-.71484v-.74854h.71484V9.07373Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M95.70461,8.437h.88086v2.48145h.07031a1.3856,1.3856,0,0,1,1.373-.80664,1.48339,1.48339,0,0,1,1.55078,1.67871v2.90723H98.69v-2.688c0-.71924-.335-1.0835-.96289-1.0835a1.05194,1.05194,0,0,0-1.13379,1.1416v2.62988h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M104.76125,13.48193a1.828,1.828,0,0,1-1.95117,1.30273A2.04531,2.04531,0,0,1,100.73,12.46045a2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27V12.688h-3.17969v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117h2.27441a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,101.63527,12.03076Z"
                style={{ fill: "#fff" }}
              />
            </g>
          </g>
        </g>
      </svg>
    </a>
  );
}

/**
 * Function used to render an accessible and clickable google play button link.
 *
 * @param {object} props
 * @param {string} props.className Optional classname passed from parent.
 * @returns {Element} Returns the google play badge as an anchor.
 */
export function GooglePlay({ className }) {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.fenix.mobile.android&pli=1"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <img src="assets/googleplay.png" height="100%" width="100%" />
    </a>
  );
}

/**
 * Function used to handle instagram logo and wrapper.
 *
 * @param {string} props.className Optional classname passed from parent.
 * @returns {Element} Returns the instagram logo as a clickable anchor.
 */
export function Instagram({ className, fill = "black" }) {
  return (
    <a
      href="https://www.instagram.com/fenix_xl_car_and_limo/"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <svg
        version="1.1"
        id="Logo"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        height="25px"
        width="25px"
        fill={fill}
      >
        <style type="text/css"></style>
        <g>
          <path
            className="st0"
            d="M256,49.5c67.3,0,75.2,0.3,101.8,1.5c24.6,1.1,37.9,5.2,46.8,8.7c11.8,4.6,20.2,10,29,18.8
		c8.8,8.8,14.3,17.2,18.8,29c3.4,8.9,7.6,22.2,8.7,46.8c1.2,26.6,1.5,34.5,1.5,101.8s-0.3,75.2-1.5,101.8
		c-1.1,24.6-5.2,37.9-8.7,46.8c-4.6,11.8-10,20.2-18.8,29c-8.8,8.8-17.2,14.3-29,18.8c-8.9,3.4-22.2,7.6-46.8,8.7
		c-26.6,1.2-34.5,1.5-101.8,1.5s-75.2-0.3-101.8-1.5c-24.6-1.1-37.9-5.2-46.8-8.7c-11.8-4.6-20.2-10-29-18.8
		c-8.8-8.8-14.3-17.2-18.8-29c-3.4-8.9-7.6-22.2-8.7-46.8c-1.2-26.6-1.5-34.5-1.5-101.8s0.3-75.2,1.5-101.8
		c1.1-24.6,5.2-37.9,8.7-46.8c4.6-11.8,10-20.2,18.8-29c8.8-8.8,17.2-14.3,29-18.8c8.9-3.4,22.2-7.6,46.8-8.7
		C180.8,49.7,188.7,49.5,256,49.5 M256,4.1c-68.4,0-77,0.3-103.9,1.5C125.3,6.8,107,11.1,91,17.3c-16.6,6.4-30.6,15.1-44.6,29.1
		c-14,14-22.6,28.1-29.1,44.6c-6.2,16-10.5,34.3-11.7,61.2C4.4,179,4.1,187.6,4.1,256c0,68.4,0.3,77,1.5,103.9
		c1.2,26.8,5.5,45.1,11.7,61.2c6.4,16.6,15.1,30.6,29.1,44.6c14,14,28.1,22.6,44.6,29.1c16,6.2,34.3,10.5,61.2,11.7
		c26.9,1.2,35.4,1.5,103.9,1.5s77-0.3,103.9-1.5c26.8-1.2,45.1-5.5,61.2-11.7c16.6-6.4,30.6-15.1,44.6-29.1
		c14-14,22.6-28.1,29.1-44.6c6.2-16,10.5-34.3,11.7-61.2c1.2-26.9,1.5-35.4,1.5-103.9s-0.3-77-1.5-103.9
		c-1.2-26.8-5.5-45.1-11.7-61.2c-6.4-16.6-15.1-30.6-29.1-44.6c-14-14-28.1-22.6-44.6-29.1c-16-6.2-34.3-10.5-61.2-11.7
		C333,4.4,324.4,4.1,256,4.1L256,4.1z"
          />
          <path
            className="st0"
            d="M256,126.6c-71.4,0-129.4,57.9-129.4,129.4S184.6,385.4,256,385.4S385.4,327.4,385.4,256
		S327.4,126.6,256,126.6z M256,340c-46.4,0-84-37.6-84-84s37.6-84,84-84c46.4,0,84,37.6,84,84S302.4,340,256,340z"
          />
          <circle className="st0" cx="390.5" cy="121.5" r="30.2" />
        </g>
      </svg>
    </a>
  );
}

export function WhatsApp({ fill = "black" }) {
  return (
    <svg
      fill={fill}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 308 308"
      height="25px"
      width="25px"
    >
      <g id="XMLID_468_">
        <path
          id="XMLID_469_"
          d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
        />
        <path
          id="XMLID_470_"
          d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
        />
      </g>
    </svg>
  );
}

export function Email({ fill = "black" }) {
  return (
    <svg
      fill={fill}
      viewBox="0 0 1920 1920"
      height="25px"
      width="25px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1694.235h1920V226H0v1468.235ZM112.941 376.664V338.94H1807.06v37.723L960 1111.233l-847.059-734.57ZM1807.06 526.198v950.513l-351.134-438.89-88.32 70.475 378.353 472.998H174.042l378.353-472.998-88.32-70.475-351.134 438.89V526.198L960 1260.768l847.059-734.57Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Phone({ fill = "black" }) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 202.592 202.592"
      height="25px"
      width="25px"
      fill={fill}
    >
      <g>
        <g>
          <path
            d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
          />
        </g>
      </g>
    </svg>
  );
}

/**
 * Function used to handle facebook logo and wrapper.
 *
 * @param {string} props.className Optional classname passed from parent.
 * @returns {Element} Returns the facebook logo as a clickable anchor.
 */
export function Facebook({ className, fill = "black" }) {
  return (
    <a
      href="https://www.facebook.com/richy.bastidas?mibextid=LQQJ4d"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 310 310"
        height="25px"
        width="25px"
        fill={fill}
      >
        <g id="XMLID_834_">
          <path
            id="XMLID_835_"
            d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
		c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
		V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
		C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
		c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
          />
        </g>
      </svg>
    </a>
  );
}

/**
 * Function used to handle FenixXL logo and wrapper.
 *
 * @param {string} props.className Optional classname passed from parent.
 * @returns {Element} Returns the FenixXL logo as a clickable anchor.
 */
export function FenixXL({ className }) {
  return (
    <Link to="/" className={className} title="Fenix XL Car and Limo">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300.000000 241.000000"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
        alt="Fenix XL Car and Limo Corporation"
      >
        <g
          transform="translate(0.000000,241.000000) scale(0.050000,-0.050000)"
          stroke="#fff"
          fill="#fff"
        >
          <path
            d="M2580 4123 c0 -11 -18 -15 -40 -9 -30 8 -38 3 -29 -19 8 -21 -5 -33
-45 -41 -31 -6 -59 -2 -63 9 -4 10 -20 3 -37 -17 -16 -20 -24 -25 -17 -13 22
45 -59 39 -116 -7 -31 -25 -96 -56 -145 -69 -48 -12 -88 -32 -88 -44 0 -13 -9
-9 -20 7 -14 22 -19 23 -20 4 0 -15 -9 -21 -20 -14 -11 7 -20 -1 -20 -17 0
-29 -107 -79 -127 -59 -23 23 -94 -69 -82 -106 8 -25 7 -29 -5 -14 -13 19 -33
15 -84 -15 -54 -34 -61 -46 -41 -70 20 -25 19 -28 -7 -18 -19 8 -39 -3 -53
-30 -13 -23 -16 -40 -7 -39 52 9 47 -8 -10 -32 -58 -25 -63 -34 -44 -70 24
-44 9 -88 -23 -68 -11 6 -25 -7 -31 -30 -6 -23 -19 -42 -28 -42 -10 0 -18 -12
-18 -26 0 -32 -109 -98 -130 -78 -9 7 -11 4 -3 -9 7 -13 -1 -28 -17 -34 -17
-7 -30 -26 -30 -44 0 -17 -9 -26 -20 -19 -11 7 -20 -3 -20 -21 0 -21 -11 -29
-29 -22 -17 6 -36 1 -43 -11 -8 -12 -6 -17 5 -10 38 23 73 -18 50 -59 -15 -25
-22 -28 -22 -9 -1 40 -31 6 -32 -35 0 -45 -55 -163 -75 -163 -9 0 -11 -8 -5
-19 6 -10 2 -24 -9 -31 -14 -8 -13 -25 1 -51 15 -29 14 -38 -3 -34 -29 6 -25
-271 3 -282 11 -3 19 -15 19 -26 0 -58 79 -95 104 -49 14 25 27 27 56 12 25
-13 42 -13 50 0 7 11 4 20 -7 20 -11 0 -16 16 -13 35 4 19 8 40 9 45 1 6 10
-3 19 -18 16 -24 24 -23 52 5 39 39 59 43 37 8 -10 -17 -4 -16 19 2 19 16 34
41 34 57 0 15 8 23 19 17 10 -7 51 6 90 28 39 22 92 40 116 40 25 -1 85 3 134
8 49 6 99 4 110 -3 32 -20 111 -20 123 -1 6 10 24 4 40 -12 16 -16 42 -24 59
-18 16 6 29 1 29 -11 0 -12 27 -22 60 -22 33 0 60 -10 60 -21 0 -12 8 -16 19
-10 10 7 30 0 44 -16 14 -15 42 -23 61 -18 20 5 36 0 36 -11 0 -12 20 -28 45
-36 56 -17 176 -129 163 -151 -6 -10 2 -17 17 -17 16 0 40 -22 55 -49 14 -27
32 -46 39 -41 14 8 72 -129 65 -155 -2 -8 7 -13 20 -10 13 3 27 -13 29 -35 3
-22 7 -60 10 -85 3 -25 19 -46 36 -46 21 -1 18 -7 -9 -18 -23 -9 -45 -7 -52 4
-7 11 -13 -2 -14 -28 -1 -93 -40 -161 -80 -139 -28 15 -37 13 -37 -7 0 -15 12
-23 26 -17 15 6 27 3 27 -7 -1 -25 -32 -71 -47 -69 -51 7 -70 -2 -61 -26 7
-17 -6 -34 -31 -42 -30 -9 -39 -24 -31 -50 6 -20 2 -42 -9 -49 -13 -8 -16 1
-8 23 8 20 6 29 -3 21 -9 -8 -28 -5 -42 6 -19 16 -22 12 -15 -18 11 -42 -54
-94 -84 -67 -8 8 -9 3 -2 -10 16 -28 -57 -74 -87 -56 -11 7 -20 3 -20 -8 0
-11 -16 -15 -35 -8 -29 11 -31 8 -11 -16 19 -24 12 -28 -50 -24 -41 2 -105 -7
-144 -20 -92 -31 -344 -33 -318 -2 13 16 3 20 -32 13 -32 -6 -50 -1 -50 16 0
14 -6 20 -13 13 -7 -8 -26 -3 -41 10 -22 18 -27 17 -22 -4 3 -15 -10 -31 -29
-35 -25 -6 -35 5 -35 36 0 33 -6 39 -25 24 -14 -12 -43 -24 -65 -28 -106 -20
232 -153 430 -170 432 -37 905 264 971 618 22 123 49 107 49 -29 -2 -674 -535
-1034 -1122 -758 -106 50 -357 251 -359 287 -1 14 -10 3 -21 -24 -15 -38 -12
-52 12 -66 17 -10 26 -25 20 -35 -6 -9 8 -23 31 -30 27 -9 38 -24 30 -45 -18
-46 61 -97 179 -113 23 -4 39 -20 37 -39 -1 -18 5 -34 15 -35 10 -2 27 -5 38
-8 11 -3 30 -6 41 -7 12 -2 17 -10 12 -18 -5 -8 19 -16 53 -17 34 -2 65 -11
68 -20 6 -19 352 -18 456 0 l60 11 -57 -31 c-36 -20 -52 -42 -45 -61 6 -16 5
-25 -4 -20 -50 31 -77 -100 -33 -164 32 -47 47 -57 55 -35 14 36 94 19 94 -20
0 -31 32 -38 48 -11 6 9 54 9 106 -1 93 -17 162 -19 266 -7 28 3 61 -3 75 -14
17 -13 25 -13 25 1 0 12 37 21 81 20 61 0 89 10 111 41 17 24 42 37 60 30 16
-7 59 2 94 19 35 18 92 43 127 57 34 14 59 35 54 48 -5 14 17 29 51 36 44 8
58 20 49 42 -8 22 1 31 32 31 24 0 38 9 31 20 -13 21 15 85 37 82 39 -6 54 0
44 16 -6 10 14 37 44 60 46 35 50 44 25 61 -16 11 -25 21 -20 22 102 19 95 12
109 96 9 50 6 63 -13 51 -15 -8 0 14 33 49 32 35 51 63 42 63 -9 0 -5 13 8 29
14 16 19 38 13 48 -6 10 1 60 16 111 16 50 20 92 10 92 -10 0 -18 10 -18 21 0
12 9 16 20 9 11 -7 20 6 20 29 0 22 10 41 21 41 12 0 15 -11 6 -24 -8 -14 -13
-88 -11 -165 4 -127 2 -136 -25 -100 -28 38 -29 38 -18 -4 6 -24 26 -47 46
-52 19 -5 34 -24 34 -41 0 -18 -7 -28 -15 -23 -9 6 -15 -49 -15 -120 0 -72 -6
-139 -12 -150 -6 -10 -1 -25 12 -33 14 -8 18 -6 10 7 -7 12 -4 25 8 29 35 11
99 272 112 458 42 586 -248 1132 -756 1424 -205 118 -278 107 -92 -13 460
-298 691 -1002 505 -1541 -142 -412 -397 -648 -802 -744 -232 -55 -889 -15
-716 44 480 162 707 510 683 1050 -28 664 -468 1068 -1165 1068 -366 0 -447
-26 -623 -202 -151 -151 -161 -130 -94 192 61 295 175 477 363 580 54 31 91
64 88 80 -35 154 185 405 481 549 895 435 1825 162 2395 -703 53 -79 101 -138
108 -132 20 21 13 89 -8 76 -12 -7 -18 10 -15 42 2 30 -5 58 -17 62 -12 4 -18
30 -13 57 7 36 1 49 -23 49 -17 0 -31 7 -29 15 6 27 -27 85 -42 76 -8 -5 -27
13 -41 40 -15 27 -37 49 -49 49 -24 0 -82 81 -75 105 2 8 -5 16 -16 18 -40 4
-50 8 -74 23 -13 8 -19 28 -13 44 11 30 -71 77 -153 88 -11 1 -20 14 -20 29 0
14 -25 39 -55 54 -30 16 -68 42 -85 59 -16 16 -43 30 -60 30 -16 0 -31 11 -32
25 -6 50 -8 52 -34 17 -25 -34 -27 -34 -55 0 -16 20 -18 29 -5 22 13 -8 29 -6
36 6 7 11 3 21 -9 23 -51 6 -58 8 -72 21 -8 8 -31 6 -50 -5 -22 -11 -41 -12
-48 -1 -6 10 0 24 14 30 14 6 -3 7 -38 1 -35 -6 -69 -2 -76 9 -7 11 -25 16
-40 10 -15 -6 -33 -1 -40 11 -7 11 -31 21 -52 21 -22 0 -39 8 -39 17 0 20 -92
18 -112 -2 -7 -7 -28 -6 -46 3 -43 23 -343 40 -330 19 12 -20 -51 -13 -87 10
-14 8 -25 7 -25 -4z"
          />
          <path
            d="M2660 3469 c-60 -14 -195 -65 -300 -111 -104 -46 -230 -95 -279 -108
-176 -47 -70 -138 162 -139 157 -1 234 42 269 151 78 238 813 233 1248 -8 943
-522 1089 -1613 323 -2408 -58 -59 -54 -78 6 -38 27 17 59 32 71 32 63 0 163
109 115 126 -14 4 -6 5 18 1 29 -5 46 6 53 35 9 31 4 38 -20 29 -27 -10 -27
-7 -6 19 16 19 41 26 65 18 33 -10 37 -5 25 33 -11 34 -8 42 14 34 18 -7 29 5
32 39 4 52 51 112 72 92 6 -7 12 11 12 39 0 29 14 66 30 82 16 17 30 51 30 78
0 26 11 59 25 74 20 22 19 24 -5 10 -25 -14 -26 -12 -5 15 14 17 20 46 13 63
-7 18 -3 33 8 33 12 0 25 -16 30 -35 5 -19 10 -2 12 38 1 41 7 90 14 110 10
33 8 33 -22 -3 -33 -39 -34 -39 -19 10 9 28 16 70 16 94 1 24 8 39 17 34 9 -6
16 9 16 32 0 27 -8 37 -23 28 -16 -10 -18 -5 -6 14 26 41 28 78 5 78 -13 0
-16 40 -9 109 7 64 4 114 -8 121 -11 7 -15 20 -10 29 6 9 -2 27 -18 40 -21 17
-24 37 -11 70 14 39 12 45 -17 37 -30 -7 -34 3 -27 65 6 52 2 70 -13 61 -13
-8 -23 -4 -23 8 0 12 -10 16 -21 9 -12 -7 -19 -1 -15 15 5 27 -58 173 -86 196
-6 6 -18 42 -25 80 -9 47 -7 60 6 41 10 -17 25 -23 33 -16 55 56 -411 350
-732 462 -314 110 -780 147 -1040 82z"
          />
        </g>
      </svg>
    </Link>
  );
}

export function HeritageRadioNetwork({ className = null }) {
  return (
    <svg
      width="171"
      height="44"
      viewBox="0 0 171 44"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M151.72 0V19.75L136.91 0H111.91V43.98H131.19V24.3L146.08 43.98H171V8.6V0H151.72Z"
        fill="currentColor"
      ></path>
      <path
        d="M91.2399 8.59998H77.6699V19.72H91.2399C94.3099 19.72 96.7999 17.23 96.7999 14.16C96.7999 11.09 94.3099 8.59998 91.2399 8.59998Z"
        fill="currentColor"
      ></path>
      <path
        d="M77.6699 43.98H93.0499L77.6699 29.92V43.98Z"
        fill="currentColor"
      ></path>
      <path
        d="M39.14 0V14.13H19.95V0H0V43.98H19.95V29.85H39.14V43.98H59.09V8.6L52.63 0H39.14Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function Spectrum({ className = null }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1708.5 469.45"
      xmlSpace="preserve"
      className={className}
      fill="white"
    >
      <g id="BADGE_-_NY1">
        <path
          className="st0"
          d="M1661.83,18.39h-352.89c-16.7,0-30.24,13.54-30.24,30.24v378.75c0,16.7,13.54,30.24,30.24,30.24h352.89 c16.7,0,30.24-13.54,30.24-30.24V48.63C1692.07,31.93,1678.53,18.39,1661.83,18.39z M1427.48,182.89h-17.87l-52.06-59.05v59.05 h-27.66V74.11h17.72l52.22,59.21V74.11h27.66V182.89z M1508.3,182.89h-27.97v-42.27l-39.93-66.51h31.7l22.69,41.49l22.69-41.49 h30.77l-39.94,66.51V182.89z M1639.89,402.2h-59.67V290.64l-38.15,17.85l-10.54-53.91l66.32-33.95h42.05V402.2z"
        />
      </g>
      <g id="NOTICIAS">
        <g>
          <path
            className="st0"
            d="M110.22,440V277.39h26.48l78.06,88.51v-88.51h41.35V440H229.4l-77.82-88.28V440H110.22z"
          />
          <path
            className="st0"
            d="M363.44,443.72c-12.39,0-23.85-2.13-34.38-6.39c-10.53-4.26-19.67-10.18-27.41-17.77 c-7.75-7.59-13.79-16.57-18.12-26.95c-4.34-10.37-6.5-21.68-6.5-33.92c0-12.08,2.17-23.27,6.5-33.57 c4.33-10.3,10.41-19.32,18.24-27.06c7.82-7.74,17-13.74,27.53-18c10.53-4.26,21.91-6.39,34.15-6.39c12.39,0,23.85,2.13,34.38,6.39 c10.53,4.26,19.67,10.18,27.41,17.77c7.74,7.59,13.78,16.57,18.12,26.95c4.33,10.38,6.5,21.68,6.5,33.92 c0,12.08-2.17,23.27-6.5,33.57c-4.34,10.3-10.42,19.32-18.24,27.06c-7.82,7.75-16.96,13.75-27.41,18 C387.25,441.59,375.83,443.72,363.44,443.72z M363.44,405.39c8.36,0,15.76-2.01,22.19-6.04c6.42-4.03,11.46-9.56,15.1-16.61 c3.64-7.05,5.46-15.06,5.46-24.04c0-8.98-1.82-16.99-5.46-24.04c-3.64-7.04-8.68-12.58-15.1-16.61 c-6.43-4.03-13.82-6.04-22.19-6.04c-8.21,0-15.56,2.01-22.07,6.04c-6.5,4.03-11.58,9.56-15.22,16.61 c-3.64,7.05-5.46,15.06-5.46,24.04c0,8.98,1.82,17,5.46,24.04c3.64,7.05,8.71,12.58,15.22,16.61 C347.87,403.38,355.23,405.39,363.44,405.39z"
          />
          <path
            className="st0"
            d="M497.71,440V313.86h-46v-36.47h133.81v36.47h-46V440H497.71z"
          />
          <path className="st0" d="M602.71,440V277.39h41.82V440H602.71z" />
          <path
            className="st0"
            d="M754.87,443.72c-17.97,0-33.68-3.56-47.16-10.69c-13.47-7.12-23.89-16.96-31.25-29.5 c-7.36-12.54-11.03-27.02-11.03-43.44c0-12.7,2.21-24.31,6.62-34.85c4.41-10.53,10.65-19.67,18.7-27.41 c8.05-7.74,17.5-13.71,28.34-17.89c10.84-4.18,22.61-6.27,35.31-6.27c9.29,0,18.12,1.08,26.48,3.25 c8.36,2.17,15.48,5.27,21.37,9.29l-6.5,36.94c-13.47-7.74-26.25-11.62-38.33-11.62c-9.45,0-17.77,2.01-24.97,6.04 c-7.2,4.03-12.82,9.56-16.84,16.61c-4.03,7.05-6.04,15.22-6.04,24.51s2.01,17.46,6.04,24.51c4.03,7.05,9.64,12.58,16.84,16.61 c7.2,4.03,15.68,6.04,25.44,6.04c6.81,0,13.71-1.04,20.68-3.14s13.09-5.15,18.35-9.18l6.74,36.94 c-5.58,4.34-12.58,7.63-21.02,9.87C774.19,442.59,764.94,443.72,754.87,443.72z"
          />
          <path className="st0" d="M821.31,440V277.39h41.82V440H821.31z" />
          <path
            className="st0"
            d="M878.93,440l72.71-162.62h26.72L1050.6,440h-46.69l-9.52-25.32h-60.63L924.23,440H878.93z M945.6,383.09 h37.17l-18.58-49.71L945.6,383.09z"
          />
          <path
            className="st0"
            d="M1113.32,443.72c-9.6,0-19.28-1.36-29.04-4.07s-17.97-6.31-24.62-10.8l9.52-34.15 c5.26,4.03,11.85,7.28,19.75,9.76c7.9,2.48,15.72,3.72,23.46,3.72c7.43,0,13.2-1.28,17.31-3.83c4.1-2.56,6.16-6.23,6.16-11.04 c0-3.1-0.74-5.65-2.21-7.67c-1.47-2.01-4.3-3.99-8.48-5.92c-4.18-1.93-10.53-4.22-19.05-6.85c-28.34-8.98-42.51-25.01-42.51-48.09 c0-10.22,2.63-19.17,7.9-26.83c5.26-7.67,12.66-13.63,22.19-17.89c9.52-4.26,20.56-6.39,33.1-6.39c8.05,0,16.22,1.16,24.51,3.48 c8.28,2.32,15.37,5.5,21.26,9.52l-6.74,34.85c-13.32-8.36-26.33-12.54-39.03-12.54c-6.82,0-12.16,1.2-16.03,3.6 c-3.87,2.4-5.81,5.77-5.81,10.11c0,3.87,1.78,7.05,5.34,9.52c3.56,2.48,9.99,5.04,19.28,7.67c16.57,4.8,28.69,11.31,36.36,19.51 c7.67,8.21,11.5,18.82,11.5,31.83c0,16.42-5.69,29.27-17.08,38.56C1148.98,439.07,1133.3,443.72,1113.32,443.72z"
          />
        </g>
      </g>
      <g id="SPECTRUM_for_Noti">
        <g>
          <path
            className="st0"
            d="M61.19,200.34c-9.33,0-18.47-1.27-27.41-3.8c-8.94-2.54-16.37-5.96-22.28-10.26l4.9-17.52 c5.6,4.3,12.4,7.72,20.41,10.26c8.01,2.54,16.05,3.8,24.14,3.8c11.2,0,20.02-2.61,26.47-7.84c6.45-5.22,9.68-12.3,9.68-21.21 c0-7.99-2.84-14.45-8.51-19.37c-5.68-4.92-15.67-9.37-29.97-13.37c-14.78-4.3-25.66-9.99-32.66-17.06 c-7-7.07-10.5-15.99-10.5-26.75c0-8.76,2.25-16.45,6.76-23.06c4.51-6.61,10.85-11.79,19.01-15.56c8.16-3.77,17.53-5.65,28.11-5.65 c7.77,0,15.47,1.08,23.09,3.23c7.62,2.16,14.38,5.15,20.29,8.99l-3.97,17.06c-5.75-3.69-12.09-6.57-19.01-8.65 c-6.92-2.08-13.73-3.11-20.41-3.11c-10.26,0-18.47,2.35-24.61,7.03c-6.14,4.69-9.21,11.03-9.21,19.02 c0,6.61,2.64,12.07,7.93,16.37c5.28,4.31,14.23,8.23,26.82,11.76c16.01,4.46,27.87,10.72,35.57,18.79 c7.7,8.07,11.55,18.02,11.55,29.86c0,14.45-5.06,25.9-15.16,34.36C92.14,196.11,78.45,200.34,61.19,200.34z"
          />
          <path
            className="st0"
            d="M148.89,197.34V35.94h48.52c18.66,0,33.2,4.38,43.62,13.14c10.42,8.76,15.63,20.91,15.63,36.43 c0,15.37-5.25,27.48-15.74,36.32c-10.5,8.84-25,13.26-43.5,13.26h-28.22v62.25H148.89z M197.41,53h-28.22v65.02h28.22 c12.6,0,22.2-2.77,28.81-8.3c6.61-5.53,9.91-13.6,9.91-24.21c0-10.76-3.31-18.87-9.91-24.33C219.61,55.73,210.01,53,197.41,53z"
          />
          <path
            className="st0"
            d="M282.55,197.34V35.94h101v17.52h-80.71v51.88h59.71v17.52h-59.71v56.95h83.04v17.52H282.55z"
          />
          <path
            className="st0"
            d="M486.88,200.34c-16.48,0-30.99-3.5-43.5-10.49c-12.52-6.99-22.24-16.72-29.16-29.17 c-6.92-12.45-10.38-26.98-10.38-43.58c0-12.3,2.06-23.55,6.18-33.78c4.12-10.22,9.87-19.1,17.26-26.63 c7.38-7.53,16.13-13.37,26.24-17.52c10.11-4.15,21.15-6.23,33.12-6.23c9.33,0,18.35,1.31,27.06,3.92 c8.71,2.62,16.33,6.3,22.86,11.07l-3.97,18.22c-6.38-4.76-13.45-8.45-21.23-11.07c-7.78-2.61-15.71-3.92-23.79-3.92 c-12.29,0-23.13,2.77-32.54,8.3c-9.41,5.53-16.76,13.22-22.04,23.06c-5.29,9.84-7.93,21.21-7.93,34.12s2.6,24.29,7.81,34.12 c5.21,9.84,12.56,17.52,22.04,23.06c9.48,5.53,20.45,8.3,32.89,8.3c8.4,0,16.68-1.34,24.84-4.04c8.16-2.69,15.35-6.42,21.58-11.18 l3.97,18.21c-6.53,4.77-14.31,8.5-23.33,11.18C505.85,198.99,496.52,200.34,486.88,200.34z"
          />
          <path
            className="st0"
            d="M606.3,197.34V53.46h-52.95V35.94h126.42v17.52h-52.95v143.88H606.3z"
          />
          <path
            className="st0"
            d="M703.8,197.34V35.94h48.98c18.66,0,33.24,4.27,43.74,12.8c10.5,8.53,15.74,20.33,15.74,35.39 c0,13.07-4.12,23.67-12.36,31.82c-8.24,8.15-19.83,13.14-34.76,14.99l62.05,66.41h-26.12l-60.18-65.71H724.1v65.71H703.8z M752.79,53H724.1v61.56h28.69c12.75,0,22.43-2.61,29.04-7.84c6.61-5.22,9.91-12.76,9.91-22.6C791.74,63.37,778.76,53,752.79,53z"
          />
          <path
            className="st0"
            d="M908.6,200.34c-13.38,0-25-2.77-34.87-8.3c-9.88-5.53-17.53-13.37-22.98-23.52 c-5.44-10.15-8.16-22.06-8.16-35.74V35.94h20.53v96.84c0,15.37,4.04,27.4,12.13,36.09c8.08,8.69,19.2,13.03,33.36,13.03 c13.84,0,24.8-4.38,32.89-13.14c8.08-8.76,12.13-20.75,12.13-35.97V35.94h20.53v96.84c0,13.53-2.72,25.36-8.16,35.51 c-5.45,10.15-13.1,18.03-22.98,23.63C933.13,197.53,921.66,200.34,908.6,200.34z"
          />
          <path
            className="st0"
            d="M1005.87,197.34l7.93-161.4h12.83l63.21,103.99l62.51-103.99h12.83l7.93,161.4h-19.83l-5.37-120.36l-55.75,92 h-5.83l-55.51-91.31l-5.13,119.67H1005.87z"
          />
        </g>
      </g>
    </svg>
  );
}
